/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import Footer from "src/components/footer"
import ImageGrid from "src/components/image-grid"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import ServiceHeader from "src/components/service-header"
import TagList from "src/components/tag-list"
import blogData from "src/data/blog/blog"
import footerData from "src/data/global/footer"

const ServicePage = ({ pageContext }) => {
  const [activeTags, setActiveTags] = useState([])
  const [posts, setPosts] = useState([])

  useEffect(() => {
    let tmpPosts = []
    if (activeTags.length !== 0) {
      pageContext.posts.forEach(post => {
        let includes = true
        activeTags.forEach(tag => {
          if (!post.meta.tags.includes(tag)) {
            includes = false
          }
        })
        if (includes) {
          tmpPosts.push({
            image: post.atf.image,
            link: post.meta.path,
          })
        }
      })
    } else {
      tmpPosts = pageContext.posts.map(post => ({
        image: post.atf.image,
        link: post.meta.path,
      }))
    }
    setPosts([...tmpPosts])
  }, [activeTags])

  const imageData = {
    hasBackground: false,
    images: posts,
  }

  return (
    <Layout>
      <Seo title={blogData.meta.title} description={blogData.meta.description} url={blogData.meta.url} />
      <ServiceHeader data={blogData.atf} />
      <TagList
        tags={pageContext.tags}
        setActiveTags={setActiveTags}
        activeTags={activeTags}
      />
      <ImageGrid data={imageData} />
      <Footer data={footerData} hasBackground />
    </Layout>
  )
}

export default ServicePage

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { v4 as uuid } from "uuid"
import Arrow from "src/assets/images/arrow.inline.svg"

import { cTagButton, cTagButtonActive, cTagClose, cTagList, cTagSection, cTagHeader, cTagArrow, cTagArrowOpen, cTagFilterButton, cFilterHeading } from "./styles.module.scss"

const TagList = ({ tags, setActiveTags, activeTags }) => {
  const breakpoints = useBreakpoint()
  const [isFilterOpen, setIsFilterOpen] = useState(breakpoints.md)

  useEffect(() => {
    setIsFilterOpen(breakpoints.md)
  }, [breakpoints])

  const toggleTag = tag => {
    let tmpTags = [...activeTags]
    if (tmpTags.includes(tag)) {
      tmpTags = tmpTags.filter(tmpTag => tmpTag !== tag)
    } else {
      tmpTags.push(tag)
    }
    setActiveTags([...tmpTags])
  }

  const cTagArrowClasses = classNames({
    [cTagArrow]: true,
    [cTagArrowOpen]: !!isFilterOpen,
  })

  const camel = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())

  return (
    <section className={cTagSection}>
      <div className={cTagHeader}>
        <strong className={cFilterHeading}>Filter</strong>
        {
          !breakpoints.md
          && (
            <button aria-label="toggle filter" className={cTagFilterButton} type="button" onClick={() => { setIsFilterOpen(!isFilterOpen) }}>
              <Arrow className={cTagArrowClasses} />
            </button>
          )
        }
      </div>
      {
        isFilterOpen
        && (
          <div className={cTagList}>
            {tags.map(tag => {
              const tagBtnClasses = classNames({
                [cTagButton]: true,
                [cTagButtonActive]: activeTags.includes(tag),
              })
              return (
                <button
                  className={tagBtnClasses}
                  type="button"
                  key={uuid()}
                  onClick={() => {
                    toggleTag(tag)
                  }}
                >
                  #{camel(tag)}
                  {activeTags.includes(tag) && <span className={cTagClose}>X</span>}
                </button>
              )
            })}
          </div>
        )
      }
    </section>
  )
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActiveTags: PropTypes.func.isRequired,
}

export default TagList

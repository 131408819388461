const meta = {
  title: "Blog + Portfolio = Blogfolio",
  description: "Eine Komination aus Blog und Portfolio? Blogfolio! Hier findest du Beiträge zu aktuellen Themen und Case Studies zu meinen Projekten. Stay tuned!",
  url: "/blog"
}

const atf = {
  heading: "<strong>Blog-Folio</strong>",
  hasBackground: true,
  image: "services-default",
  alt: "icon of html tag"
}

export default {
  meta,
  atf,
}
